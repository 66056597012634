import { AuthError } from './AuthApi';
import { appConfig } from '../config';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { AxiosError } from 'axios';

const EVO_DISCOVERY_VERSION = '2';

export interface EvoHub {
    code: string;
    display_name: string;
    url: string;
}

export interface EvoOrg {
    id: string;
    display_name: string;
}

interface EvoService {
    code: string;
    display_name: string;
}

export interface EvoServiceAccess {
    hub_code: string;
    org_id: string;
    services: string[];
}

export interface EvoDiscoveryResponse {
    hubs: EvoHub[];
    organizations: EvoOrg[];
    services: EvoService[];
    service_access: EvoServiceAccess[];
}

export class EvoApi {
    static discover = async (
        axiosDriverFlask: AxiosDriverFlaskInstance,
        accessToken: string,
        serviceCode: string | string[]
    ) => {
        let response: EvoDiscoveryResponse;

        const params = new URLSearchParams();
        if (typeof serviceCode === 'string') {
            params.append('service', serviceCode);
        } else {
            serviceCode.forEach((value) => params.append('service', value));
        }

        try {
            response = (
                await axiosDriverFlask.get(`${appConfig.evoBaseUrl}/evo/identity/v${EVO_DISCOVERY_VERSION}/discovery`, {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    withCredentials: false, // If this is True, the browser will send a preflight request and the EVO discovery response doesn't have ACCESS-CONTROL-ALLOW-CREDENTIALS set to true.
                })
            ).data.discovery;
        } catch (e) {
            if ((e as AxiosError).response?.status === 401) {
                throw new AuthError('Unauthorized', { cause: e });
            }

            throw e;
        }

        const requiredServices = Array.from(params.values());
        const serviceAccesses = response.service_access.filter(
            (sa) => sa.services.filter((s) => requiredServices.includes(s)).length === requiredServices.length
        );

        return {
            ...response,
            service_access: serviceAccesses,
        };
    };
}
