import CssBaseline from '@mui/material/CssBaseline';
import Login from 'App/Login/Login';
import PageHead from 'App/PageHead';

export default function IndexScreen() {
    return (
        <>
            <PageHead />
            <Login />
            <CssBaseline />
        </>
    );
}
