import Head from 'next/head';

export default function PageHead() {
    return (
        <Head>
            <title>Driver</title>
            <link rel="shortcut icon" type="image/jpg" href="/DriveBadgeRGB.svg" />
        </Head>
    );
}
