import React from 'react';
import { Session, clearAuthSessionOrgsAndHubAccesses, getSession, setAuthSessionOrgsAndHubAccesses } from './Session';
import { AuthApi } from 'Common/api/AuthApi';

import { appConfig } from 'Common/config';
import {
    errorNotificationDataCy,
    hubsDropdownDataCy,
    loadingContainerDataCy,
    loginButtonDataCy,
    orgsDropdownDataCy,
    saveSelectionCheckboxDataCy,
    selectButtonDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { tss } from 'tss-react/mui';
import { LoginBackground } from 'App/Login/LoginBackground';
import { SelectMenu } from '@local/web-design-system/dist/components/SelectMenu';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import {
    getAvailableOrgsForDropdown,
    getDiscovery,
    goToApp,
    resumeLogin,
    getAvailableHubsForDropdown,
} from 'App/Login/loginService';
import { Notification, NotificationType } from '@local/web-design-system/dist/components/Notification';
import PrimaryButton from 'Common/components/Core/PrimaryButton';
import GenericCheckbox from 'App/MainApp/Dialogs/Shared/GenericCheckbox';
import {
    getAvailableHubs,
    getOrgName,
    getPrimitiveHubChoice,
    getPrimitiveOrgChoice,
    getSavedHubChoice,
    getSavedOrgChoice,
    saveHubChoice,
    saveOrgChoice,
} from 'Common/api/AuthUtils';
import { useSessionContext } from 'App/context/SessionContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = tss.create(({ theme }) => ({
    errorContainer: {
        height: '60px',
        marginBottom: '32px',
    },
    loadingContainer: {
        marginTop: '31px',
    },
    loginButton: {
        marginTop: '31px',
    },
    dropdownLabel: {
        fontSize: '14px',
    },
    orgsDropdown: {
        marginTop: '10px',
        color: theme.palette.grey[0],
        border: `1px solid ${theme.palette.grey[700]}`,
        background: theme.palette.grey[900],
        '&:hover': {
            color: theme.palette.grey[0],
            background: theme.palette.grey[900],
        },
    },
    orgsDropdownOpen: {
        color: theme.palette.grey[0],
    },
    checkboxContainer: {
        alignItems: 'center',
        marginTop: '13px',
    },
    checkbox: {
        color: theme.palette.grey[500],
    },
    checkedCheckbox: {
        color: theme.palette.grey[0],
    },
    checkboxLabel: {
        fontSize: '14px',
        color: theme.palette.grey[500],
    },
    selectButton: {
        marginTop: '22px',
    },
}));

interface OrgOption {
    label: string;
    key: ObjectIDType;
}

enum States {
    IDLE = 'Idle',
    SELECT_ORG = 'SelectOrg',
    SELECT_HUB = 'SelectHub',
}

export default function Login() {
    const { classes } = useStyles();
    const { axiosDriverFlask } = useSessionContext();

    const [state, setState] = React.useState(States.IDLE);
    // const [discovery, setDiscovery] = React.useState<EvoDiscoveryResponse>(null);
    const [orgs, setOrgs] = React.useState<OrgOption[]>([]);
    const [hubs, setHubs] = React.useState<OrgOption[]>([]);
    const [selectedOrg, setSelectedOrg] = React.useState(null);
    const [selectedHub, setSelectedHub] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [saveSelection, setSaveSelection] = React.useState(Boolean(getSavedHubChoice() || getSavedOrgChoice()));
    const [isLoading, setIsLoading] = React.useState(false);

    const newUrlScheme = useFlags()?.newUrlScheme;

    const continueToHubSelectionStep = (orgId: string) => {
        const session = getSession();
        const availableHubs = getAvailableHubs(session.serviceAccesses, session.hubs, orgId);
        if (availableHubs.length === 0) {
            setErrorMessage('You do not have access to any hubs');
            return;
        }

        const primitiveHubChoice = getPrimitiveHubChoice(availableHubs);
        if (!primitiveHubChoice) {
            return enterSelectHubState(orgId);
        }
        setIsLoading(true);
        return goToApp(orgId, primitiveHubChoice.code, axiosDriverFlask, newUrlScheme);
    };

    const continueToOrgSelectionStep = async (session: Session) => {
        const discovery = await handleDiscovery(session);

        if (!discovery) {
            return;
        }

        const availableOrgs = discovery.organizations;

        const primitiveOrgChoice = getPrimitiveOrgChoice(availableOrgs);
        if (!primitiveOrgChoice) {
            return enterSelectOrgState();
        }
        return continueToHubSelectionStep(primitiveOrgChoice.id);
    };

    // on page load we decide whether to let user choose an org
    React.useEffect(() => {
        async function firstLoad() {
            const session = getSession();
            const params = new URLSearchParams(window.location.search.substring(1));

            if (params.has('code') && params.has('state')) {
                const code = params.get('code');
                const state = params.get('state');

                await enterLoginResumedState(code, state);

                // Remove oauth code and state from the url to prevent stale requests
                params.delete('code');
                params.delete('state');
                const paramsStr = params.toString() ? `?${params.toString()}` : '';
                const newUrl = `${window.location.pathname}${paramsStr}`;
                window.history.replaceState(undefined, '', newUrl);
            } else if (session) {
                return continueToOrgSelectionStep(session);
            } // else we stay in Idle state
        }

        void firstLoad();
    }, []);

    const enterLoginStartedState = async () => {
        try {
            setIsLoading(true);
            const authorizationUrl = await AuthApi.startLogin(appConfig.idpExtraScopes);
            window.location.href = authorizationUrl;
        } catch (e) {
            console.error('Failed to get auth state from the backend:', e);
            setErrorMessage('Something went wrong. Please try again later.');
        }
    };

    const enterLoginResumedState = async (oauthCode: string, oauthState: string) => {
        let session: Session;

        try {
            const formData = new FormData();
            formData.append('code', oauthCode);
            formData.append('state', oauthState);

            setIsLoading(true);
            session = await resumeLogin(oauthCode, oauthState, axiosDriverFlask);
            setIsLoading(false);
        } catch (error) {
            setErrorMessage(error.message);
            setIsLoading(false);

            return;
        }

        return continueToOrgSelectionStep(session);
    };

    const handleDiscovery = async (session: Session) => {
        setIsLoading(true);
        const discoveryResponse = await getDiscovery(axiosDriverFlask, session);
        setIsLoading(false);

        if (discoveryResponse.errorMessage) {
            clearAuthSessionOrgsAndHubAccesses();
            setErrorMessage(discoveryResponse.errorMessage);
            return undefined;
        }

        setAuthSessionOrgsAndHubAccesses(discoveryResponse.discovery);
        return discoveryResponse.discovery;
    };

    const enterSelectOrgState = async () => {
        const session = getSession();
        const availableOrgsForDropDown = getAvailableOrgsForDropdown(session.organizations);

        if (availableOrgsForDropDown.length === 0) {
            setErrorMessage('You do not have access to any organizations');
            return;
        }

        setOrgs(availableOrgsForDropDown);
        setSelectedOrg(availableOrgsForDropDown[0].key);

        setState(States.SELECT_ORG);
    };

    const enterSelectHubState = async (orgId: string) => {
        const session = getSession();
        const availableHubsForDropDown = getAvailableHubsForDropdown(session.serviceAccesses, session.hubs, orgId);

        setSelectedOrg(orgId);

        setHubs(availableHubsForDropDown);
        setSelectedHub(availableHubsForDropDown[0].key);

        setState(States.SELECT_HUB);
    };

    const switchToSelectedOrg = async () => {
        // setErrorMessage(null);
        if (saveSelection) {
            saveOrgChoice(selectedOrg);
        } else {
            saveOrgChoice(undefined);
        }

        return continueToHubSelectionStep(selectedOrg);
    };

    const switchToSelectedHub = async () => {
        // setErrorMessage(null);
        if (saveSelection) {
            saveHubChoice(selectedHub);
        } else {
            saveHubChoice(undefined);
        }

        try {
            setIsLoading(true);
            await goToApp(selectedOrg, selectedHub, axiosDriverFlask, newUrlScheme);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const onSaveSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveSelection(event.target.checked);
    };

    const isIdleState = state === States.IDLE && !isLoading;
    const isChooseOrgState = !errorMessage && state === States.SELECT_ORG && !isLoading;
    const isChooseHubState = !errorMessage && state === States.SELECT_HUB && !isLoading;

    return (
        <LoginBackground>
            <Grid item container flexDirection="column">
                <Grid className={classes.errorContainer}>
                    {errorMessage && (
                        <div data-cy={errorNotificationDataCy}>
                            <Notification message={errorMessage} type={NotificationType.ERROR} />
                        </div>
                    )}
                </Grid>

                {isLoading && <Grid data-cy={loadingContainerDataCy}>Loading...</Grid>}

                {isIdleState && (
                    <>
                        <PrimaryButton
                            onClick={enterLoginStartedState}
                            data-cy={loginButtonDataCy}
                            className={classes.loginButton}
                        >
                            Sign In
                        </PrimaryButton>
                    </>
                )}

                {isChooseOrgState && (
                    <>
                        <Typography className={classes.dropdownLabel}>Select an Organisation</Typography>
                        <div data-cy={orgsDropdownDataCy}>
                            <SelectMenu
                                options={orgs}
                                onSelect={setSelectedOrg}
                                selected={selectedOrg}
                                classes={{ button: classes.orgsDropdown, selected: classes.orgsDropdownOpen }}
                            />
                        </div>
                        <Grid item container className={classes.checkboxContainer}>
                            <GenericCheckbox
                                className={classes.checkbox}
                                checked={saveSelection}
                                onChange={onSaveSelectionChange}
                                classes={{ checked: classes.checkedCheckbox }}
                                data-cy={saveSelectionCheckboxDataCy}
                            />
                            <Typography className={classes.checkboxLabel}>Remember this selection</Typography>
                        </Grid>
                        <PrimaryButton
                            onClick={switchToSelectedOrg}
                            data-cy={selectButtonDataCy}
                            className={classes.selectButton}
                        >
                            Select
                        </PrimaryButton>
                    </>
                )}

                {isChooseHubState && (
                    <>
                        <Typography className={classes.dropdownLabel}>
                            Select a Hub in Organization "{getOrgName(selectedOrg)}"
                        </Typography>
                        <div data-cy={hubsDropdownDataCy}>
                            <SelectMenu
                                options={hubs}
                                onSelect={setSelectedHub}
                                selected={selectedHub}
                                classes={{ button: classes.orgsDropdown, selected: classes.orgsDropdownOpen }}
                            />
                        </div>
                        <Grid item container className={classes.checkboxContainer}>
                            <GenericCheckbox
                                className={classes.checkbox}
                                checked={saveSelection}
                                onChange={onSaveSelectionChange}
                                classes={{ checked: classes.checkedCheckbox }}
                                data-cy={saveSelectionCheckboxDataCy}
                            />
                            <Typography className={classes.checkboxLabel}>Remember this selection</Typography>
                        </Grid>
                        <PrimaryButton
                            onClick={switchToSelectedHub}
                            data-cy={selectButtonDataCy}
                            className={classes.selectButton}
                        >
                            Select
                        </PrimaryButton>
                    </>
                )}
            </Grid>
        </LoginBackground>
    );
}
