import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import Image from 'next/image';
import { tss } from 'tss-react/mui';
import classNames from 'classnames';
import { getImageUrl } from 'Common/utils/utils';

const useStyles = tss.create(({ theme }) => {
    const bottomSpacing = '23px';
    const sideSpacing = '27px';

    return {
        root: {
            width: '100vw',
            height: '100vh',
            color: theme.palette.grey[0],
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        },
        left: {
            flexDirection: 'column',
        },
        right: {
            backgroundImage: 'linear-gradient(0deg, rgba(29, 31, 35, 0.76) 0%, rgba(29, 31, 35, 0.9) 100%)',
            flexWrap: 'nowrap',
        },
        logoContainer: {
            marginTop: '389px',
            justifyContent: 'flex-end',
            paddingRight: '33px',
        },
        leftBottom: {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexGrow: '1',
        },
        copyright: {
            color: theme.palette.grey[0],
            marginLeft: sideSpacing,
            marginBottom: bottomSpacing,
            fontSize: '14px',
        },
        divider: {
            borderColor: theme.palette.grey[300],
        },
        main: {
            paddingTop: '295px',
            marginLeft: '50px',
            flex: '0 0 360px',
        },
        rightRight: {
            flexDirection: 'column',
        },
        supportContainer: {
            width: '317px',
            alignSelf: 'flex-end',
            flexDirection: 'column',
            marginTop: '90px',
            marginRight: '60px',
        },
        seequentLogo: {
            marginBottom: '30px',
        },
        supportTitle: {
            color: theme.palette.grey[0],
        },
        supportDivider: {
            margin: '11px 0 21px',
            opacity: '0.25  ',
        },
        supportBody: {
            color: theme.palette.grey[400],
            fontSize: '13px',
        },
        rightBottom: {
            paddingBottom: bottomSpacing,
            paddingRight: sideSpacing,
            flexGrow: 1,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },
        linksContainer: {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        a: {
            textDecoration: 'none',
        },
        link: {
            color: theme.palette.grey[400],
            fontSize: '13px',
            textDecoration: 'underline',
        },
        linkDivider: {
            height: '12px',
            margin: '0 10px',
            borderColor: theme.palette.grey[400],
        },
    };
});

const supportText =
    'Driver is an automatic modelling solution that helps you discover important features and patterns in geoscience drilling data. For assistance, please contact support at support@seequent.com';

const backgroundImageFileName = 'driver-login-image.jpg';
const logoFileName = 'DriverRGBWhiteColour.svg';
const seequentLogo = 'seequent-white.svg';

export function LoginBackground(props: { children: ReactNode }) {
    const { classes } = useStyles();

    const backgroundUrl = getImageUrl(backgroundImageFileName);
    const logoUrl = getImageUrl(logoFileName);
    const seequentLogoUrl = getImageUrl(seequentLogo);

    return (
        <Grid container style={{ backgroundImage: `url(${backgroundUrl})` }} className={classes.root}>
            <Grid item container xs={4} className={classes.left}>
                <Grid item container className={classes.logoContainer}>
                    <Image priority alt="Driver logo" src={logoUrl} height={80} width={260} />
                </Grid>
                <Grid item container className={classes.leftBottom}>
                    <Typography className={classes.copyright}>
                        © Seequent, The Bentley Subsurface Company. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={8} className={classes.right}>
                <Grid item container className={classes.main}>
                    {props.children}
                </Grid>
                <Grid item container className={classes.rightRight}>
                    <Grid item container className={classes.supportContainer}>
                        <div className={classes.seequentLogo}>
                            <Image alt="Seequent logo" src={seequentLogoUrl} height={25} width={150} />
                        </div>
                        <Typography className={classes.supportTitle} variant="h1">
                            Support
                        </Typography>
                        <Divider className={classNames(classes.divider, classes.supportDivider)} />
                        <Typography className={classes.supportBody}>{supportText}</Typography>
                    </Grid>
                    <Grid item container className={classes.rightBottom}>
                        <Grid item container className={classes.linksContainer}>
                            <a
                                href="https://www.seequent.com/legal-privacy/online-services-user-terms/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.a}
                            >
                                <Typography component="span" className={classes.link}>
                                    Terms of Use
                                </Typography>
                            </a>
                            <Divider
                                className={classNames(classes.divider, classes.linkDivider)}
                                orientation="vertical"
                            />
                            <a
                                href="https://www.seequent.com/legal-privacy/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.a}
                            >
                                <Typography component="span" className={classes.link}>
                                    Privacy Policy
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
