import { Session, clearAuthSession, setAuthSessionOauthAndUser } from 'App/Login/Session';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { AuthApi, AuthError } from 'Common/api/AuthApi';
import { getAvailableHubs, getHubUrl, setCurrentHubCode } from 'Common/api/AuthUtils';
import { EvoDiscoveryResponse, EvoApi, EvoOrg, EvoHub, EvoServiceAccess } from 'Common/api/EvoApi';
import { appConfig } from 'Common/config';
import { URLParams } from 'Common/types/Router/URLParams';

export async function getDiscovery(axiosDriverFlask: AxiosDriverFlaskInstance, session: Session) {
    let discovery: EvoDiscoveryResponse;

    const requiredServices = appConfig.evoServiceCodes;

    const accessToken = session?.oauth?.accessToken;

    if (!accessToken) {
        return {
            errorMessage: '', // likely caused by expired token so don't want to show an error
        };
    }

    try {
        discovery = await EvoApi.discover(axiosDriverFlask, accessToken, requiredServices);
    } catch (e: any) {
        if (e instanceof AuthError) {
            clearAuthSession();
            return {
                errorMessage: '', // likely caused by expired token so don't want to show an error
            };
        } else {
            return {
                errorMessage: 'There was an error',
            };
        }
    }

    if (discovery.service_access.length === 0) {
        clearAuthSession();
        return {
            errorMessage: "You can't use this service. Please contact support",
        };
    }

    return {
        discovery,
    };
}

export async function goToApp(
    selectedOrgUuid: string,
    selectedHubCode: string,
    // discovery: EvoDiscoveryResponse,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    newUrlScheme: boolean
) {
    setCurrentHubCode(selectedHubCode);
    try {
        await axiosDriverFlask.get(`${getHubUrl()}/health_check?check_dependencies=true`);
    } catch (error) {
        throw 'Service is currently down for the selected organisation.';
    }

    const url = `${newUrlScheme ? '' : '/org'}/${selectedOrgUuid}/${URLParams.PROJECT}`;

    window.location.href = url;
}

export async function resumeLogin(oauthCode: string, oauthState: string, axiosDriverFlask: AxiosDriverFlaskInstance) {
    let session: Session | AuthError;

    try {
        const formData = new FormData();
        formData.append('code', oauthCode);
        formData.append('state', oauthState);

        session = await AuthApi.continueLogin(axiosDriverFlask, oauthState, oauthCode);
    } catch (e: any) {
        console.error('Backend returned an error:', e);

        let message = 'Something went wrong. Please try again soon';

        if (e.response?.status === 404) {
            // stale oauth parameters
            message = 'Something went wrong. Please try again';
        } else if (e.response?.status === 401) {
            clearAuthSession();
            message = 'You are not authorized to use this application';
        }

        throw message;
    }

    if (session instanceof Error) {
        console.error(session);
        throw 'Something went wrong. Please try again soon';
    }

    setAuthSessionOauthAndUser(session.oauth, session.user);

    // // Remove oauth code and state from the url to prevent stale requests
    // window.history.replaceState(window.history.state, '', `${window.location.pathname}`);

    return session;
}

export function getAvailableOrgsForDropdown(orgs: EvoOrg[]) {
    const availableOrgs = orgs.map((org) => ({
        key: org.id,
        label: org.display_name,
    }));

    return availableOrgs;
}

export function getAvailableHubsForDropdown(serviceAccesses: EvoServiceAccess[], hubs: EvoHub[], orgId: string) {
    const availableHubs = getAvailableHubs(serviceAccesses, hubs, orgId).map((hub) => ({
        key: hub.code,
        label: hub.display_name,
    }));

    return availableHubs;
}
